import { graphql, useStaticQuery } from "gatsby"
import type { DomainExtensionCategory } from "~/types/squidex"

interface Query {
  GraphCmsSquidex: {
    categories: DomainExtensionCategory[]
  }
}

type Link = {
  flatData: {
    url: string
  }
}[]

const pages: Link = [
  {
    flatData: {
      url: "/szukaj-domeny/",
    },
  },
]

export default function useDomainExtensionsByCategories(): DomainExtensionCategory[] {
  const {
    GraphCmsSquidex: { categories },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        categories: queryDomainExtensionCategoryContents(
          orderby: "data/priority/iv asc"
        ) {
          id
          flatData {
            name
            slug
            domains {
              flatData {
                id
                title
                tooltip
                description
                pages {
                  ... on GraphCmsSquidex_GenericPage {
                    id
                    flatData {
                      url
                    }
                  }
                }
                extension
                periods {
                  ...CloudBluePeriodValue
                }
                logo {
                  id
                  url
                  fileName
                }
              }
            }
          }
        }
      }
    }
  `)

  return categories.map((category) => ({
    ...category,
    flatData: {
      ...category.flatData,
      domains: category.flatData.domains.map((domain) => ({
        ...domain,
        flatData: {
          ...domain.flatData,
          pages,
        },
      })),
    },
  }))
}

import React from "react"
import { Link } from "gatsby"
import Hel from "@onestore/hel/dist/Hel"
import { default as strefaTheme } from "@onestore/hel/dist/theme/strefa"

export const wrapPageElement = ({ element }) => (
  <Hel brand="strefa" env="production" theme={strefaTheme} linkComponent={Link}>
    {element}
  </Hel>
)
